@import "../../styles/helpers/variables";
@import "../../styles/helpers/mixins";




.blog-item {
    min-height: 350px;
    border-radius: 5px;
    background-color: $BackgroundColor;
    padding: 30px 0;
    .__image {
        overflow: hidden;
        height: 400px;
        position: relative;
        background-color: black;
        cursor: pointer;
        img{
            @include imageInsideDiv($max-width: 100%);
        }
        
    }
    .__title {
        a{
            display: inline;
        }
        h3 {
            display: inline;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
        margin-bottom: 5px;
        font-size: 40px;
        font-weight: 600;
        color: $mainFontColor; 
       
    }
    .__date {
        font-size: 14px;
        color: #999;
    }
    .__description {
        color: #999;
        margin: 10px 0;
    }
    .__link_w {
        padding: 15px 25px;
        border-radius: 2px;
        border: 1px solid $mainFontColor;
        width: fit-content;
        cursor: pointer;
        transition: background-color 0.1s ease-in-out;
        &:hover {
            background-color: $mainFontColor;
            color: $BackgroundColor;
        }
    }
    .__link {
        font-size: 16px;
        text-decoration: none;

    }
    
}


.content-container {
    padding: 5vw 25vw;
    min-height: 80vh;


    @media screen and (max-width: 450px) {
        padding: 10% 4%;
    }
}

.blog-c {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 50px;
}

.blog-post {
    h1{
        font-size: clamp(3.5vw,55px,9vw);
        font-weight: 600;
    }
    div:before {
        content: "";
        display: block;
        height: 90px; /* fixed header height*/
        margin: -90px 0 0; /* negative fixed header height */
      }
}