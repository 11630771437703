@import "../styles/helpers/variables"

    
    
.page-content 
    margin-top: 50px
    padding: 0% 4%
    opacity: 0
    &.blog
        margin: 0 auto
        max-width: 1400px
    &.no-padding
        padding: 0

    &.fade-in
        animation: fadeIn 0.3s ease-in-out forwards 
        @keyframes fadeIn
            from
                opacity: 0
            to
                opacity: 1

    &.stretch
        margin-top: 80px
        padding: 0% 22%
        min-height: 150vh
        @media screen and (max-width: 1024px)
            padding: 0% 10%


        @media screen and (max-width: 450px)
            padding: 0% 3%
    
    &.white
        background-color: $mainFontColor 

        color: $BackgroundColor


.h1-absolute
    position: absolute
    top: 10%
    left: 20%
    font-size: 7rem
    transform: translateX(-50%)
    @media screen and (max-width: 450px)
        font-size: 5rem
        top: 15%
        left: 50%
        transform: translateX(-50%)
        



.page-index 
    position: fixed
    top: 8vh
    width: 320px
    height: 90vh
    border-right: 1px solid $mainFontColor
    padding: 20px
    text-transform: capitalize

    .content 
        border-bottom: 1px solid $mainFontColor
        margin-bottom: 10px
        padding-bottom: 5px
