@import "../../../styles/helpers/variables.sass";
@import "../../../styles/helpers/mixins";


.card-image {
    position: relative;
    max-width: 450px;
    .back {
        z-index: 0;
        position: absolute;
        background-color: $colorEnphasis;
        width: 100%;
        height: 100%;
       
    }

    .content{
        position: relative;
        z-index: 2;
        
        transition: all 0.2s ease-in-out;  
        height: 100%;
        background-color: $BackgroundColor;
        &:hover {
            transform: translateY(-10px) scale(1.01);
        }
        p {
            margin: 0;
        }
    
        img {
            width: 100%;
            transition: all 0.2s ease-in-out;
            &:hover {
                opacity: 0.6;
            }
        }
    }

    .text{
        padding: 1%;
        :first-child {
            font-size: 18px;
            font-weight: 500;
        }
       
    }
}