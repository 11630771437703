@import "../../../styles/helpers/variables"
@import "../../../styles/helpers/mixins"

.footer
    @include fillScreenDiv($BackgroundColor: $BackgroundColor, $colorText:$mainFontColor,$width: 100%, $height: auto )

    position: relative
    padding-top: 7vh

    .contact-button
        @include setAnchorAsButton($BackgroundColor: none)
        border-left: 1px solid $mainFontColor
        border-right: 1px solid $mainFontColor
        display: flex
        align-items: center
        padding: 0 5px

        &:hover
            background-color: $mainFontColor
            color: $BackgroundColor
    
    .img-wrapper
        overflow: hidden
        height: 100%
        width: 60px
        position: relative

        img
            @include imageInsideDiv($max-width: 100%)
            

    .end-footer
        height: 60px
        border: 1px solid $mainFontColor
        
        display: flex
        flex-direction: row

    .end-layout
        display: flex
        flex-direction: row
        margin-left: auto
        justify-content: space-around

        .social-item
            @include removeDefaultLinkStyle()
            display: flex
            align-items: center
            justify-content: center
            width: 80px
            border-left: 1px solid $mainFontColor
            &:hover
                background-color: $mainFontColor
                color: $BackgroundColor
