@import "../../styles/helpers/variables"
@import "../../styles/helpers/mixins"

.list-item

    color: $BackgroundColor
    font-size: 18px
    min-width: 100%
    max-width: 100%
    padding: 1vw
    border-radius: 5px
    border: 2.5px solid $BackgroundColor
    border-bottom: 5px solid $BackgroundColor
    transition: all 0.1s ease-in-out
    display: flex
    flex-direction: column
    justify-content: space-evenly
    align-items: flex-start

    &:hover
        transform: translateY(-8px)
        box-shadow: 0px 8px 0px 0px $colorSecondary
    h3
        font-size: clamp(12px,30px,6vw)
        margin-bottom: 10px

    p
        font-size: clamp(12px,18px,4.5vw)
        font-weight: 300
        margin-bottom: 20px

    a
        @include anchorStyle()
        background-color: aliceblue
        &:active
            background-color: $colorSecondary

    @media screen and (max-width: 450px)
        width: 100%
        padding: 2%
        @include mobileCardAnimation()

        a
            padding: 3.5% 5%
