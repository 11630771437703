@import "../../../styles/helpers/variables"
@import "../../../styles/helpers/mixins"

.nav-panel-wrapper
    position: absolute

    .bar
        position: fixed
        padding: 10px 0
        top: 0
        left: 0
        z-index: 4

    .nav-button
        --s: 32px /* control the size */
        --c: white /* the color */
        position: fixed
        padding: 0
        top: 0
        left: 0
        z-index: 4
        top: 1rem
        height: var(--s)
        aspect-ratio: 1
        border: none
        border-inline: calc(var(--s)/2) solid #0000
        box-sizing: content-box
        --_g1: linear-gradient(var(--c) 20%,#0000 0 80%,var(--c) 0) no-repeat content-box border-box 
        --_g2: radial-gradient(circle closest-side at 50% 12.5%,var(--c) 95%,#0000) repeat-y content-box border-box 
        background: var(--_g2) left  var(--_p,0px) top, var(--_g1) left  calc(var(--s)/10 + var(--_p,0px)) top, var(--_g2) right var(--_p,0px) top, var(--_g1) right calc(var(--s)/10 + var(--_p,0px)) top 
        background-size: 20% 80%, 40% 100% 
        mix-blend-mode: difference
        clip-path: inset(0 25%)
        -webkit-mask: linear-gradient(90deg,#0000,#000 25% 75%,#0000)
        cursor: pointer
        transition: background-position .3s var(--_s,.3s), clip-path 0s var(--_s,.6s) 
        -webkit-appearance: none
        -moz-appearance: none
        appearance: none

        &:before, &:after
            content: ""
            position: absolute
            border-radius: var(--s)
            inset: 40% 0
            background: var(--c)
            transition: transform .3s calc(.3s - var(--_s,.3s))

        &.open
            clip-path: inset(0)
            --_p: calc(-1*var(--s))
            --_s: 0s

        &.open:before
            transform: rotate(45deg)

        &.open:after
            transform: rotate(-45deg)

        &:focus-visible
            clip-path: none
            -webkit-mask: none
            border: none
            outline: 2px solid var(--c)
            outline-offset: 5px

.nav-panel
    display: flex
    flex-direction: column
    top: 0
    left: 0
    padding-top: 100px
    padding-left: 3%
    margin-left: auto
    position: fixed
    width: 100%
    height: 100vh
    background-color: $BackgroundColor
    z-index: 1
    transform: translateX(-100%)
    border-top-right-radius: 150px
    border-bottom-right-radius: 150px
    border-right: 1.5px solid $mainFontColor
    transition: all 0.4s ease-in-out
    overflow: auto

    div
        cursor: pointer
        font-size: clamp(1vw,85px,6vw)
        color: $mainFontColor
        span
            font-size: clamp(1vw,85px,6vw)
            &:hover
                color: $colorEnphasis

    a
        color: $mainFontColor
        font-size: clamp(1vw,85px,7vw)
        transition: all 0.5s ease
        display: inline-block
        &::before
            content: ''
            display: inline-block
            width: 0px
            height: 50%
            overflow: hidden
            transition: all 1s ease

        &:hover
            color: $colorEnphasis
            text-decoration: underline
            &::before
                content: ''
                width: 120px
                margin-right: 10px
                background-color: $colorEnphasis
                color: $mainFontColor

    &.open
        transform: translateX(0%)
        border-top-right-radius: 0%
        border-bottom-right-radius: 0%

      