@import '../styles/helpers/variables.sass';


.container-cube {
  transform: rotate(45deg);
  position: absolute;
  top: 20%;
  right: 20%;
  @media screen and (max-width: 450px) {
    transform: rotate(45deg) scale(0.6);
    left: 39%;
    top: 20%;
  }
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  margin: 0 -80px 0 0;
}

.cube {
  position: relative;
  width: 1px;
  height: 1px;
  margin: 0 80px 0 0;
  transform-style: preserve-3d;
}
.wall {
  width: 20px;
  height: 20px;
  position: absolute;
  left: calc(-80px / 2);
  top: calc(-80px / 2);
  text-align: center;
  line-height: 100px;
  border: solid 1px #fff;
}
.front {
  transform: translateZ(calc(30px / 2));
}
.back {
  transform: translateZ(calc(-30px / 2)) rotateY(180deg);
}
.right {
  transform: translateX(calc(30px / 2)) rotateY(90deg);
}
.left {
  transform: translateX(calc(-30px / 2)) rotateY(-90deg);
}
.top {
  transform: translateY(calc(-30px / 2)) rotateX(90deg);
}
.bottom {
  transform: translateY(calc(30px / 2)) rotateX(-90deg);
}

/* animation */

//first
.flex:nth-of-type(4) .cube:nth-of-type(1) {
  animation: rotation 3s ease-in-out 0.5s infinite alternate;
}

//sec
.flex:nth-of-type(3) .cube:nth-of-type(1) {
  animation: rotation 3s ease-in-out 0.6s infinite alternate;
}
.flex:nth-of-type(4) .cube:nth-of-type(2) {
  animation: rotation 3s ease-in-out 0.6s infinite alternate-reverse;
}

//third
.flex:nth-of-type(2) .cube:nth-of-type(1) {
  animation: rotation 3s ease-in-out 0.7s infinite alternate;
}
.flex:nth-of-type(3) .cube:nth-of-type(2) {
  animation: rotation 3s ease-in-out 0.6s infinite alternate-reverse;
}
.flex:nth-of-type(4) .cube:nth-of-type(3) {
  animation: rotation 3s ease-in-out 0.8s infinite alternate;
}

//forth
.flex:nth-of-type(1) .cube:nth-of-type(1) {
  animation: rotation 3s ease-in-out 0.8s infinite alternate;
}
.flex:nth-of-type(2) .cube:nth-of-type(2) {
  animation: rotation 3s ease-in-out 0.6s infinite alternate-reverse;
}
.flex:nth-of-type(3) .cube:nth-of-type(3) {
  animation: rotation 3s ease-in-out 0.8s infinite alternate;
}
.flex:nth-of-type(4) .cube:nth-of-type(4) {
  animation: rotation 3s ease-in-out 0.8s infinite alternate-reverse;
}

//five
.flex:nth-of-type(1) .cube:nth-of-type(2) {
  animation: rotation 3s ease-in-out 0.6s infinite alternate-reverse;
}
.flex:nth-of-type(2) .cube:nth-of-type(3) {
  animation: rotation 3s ease-in-out 0.9s infinite alternate;
}
.flex:nth-of-type(3) .cube:nth-of-type(4) {
  animation: rotation 3s ease-in-out 0.8s infinite alternate-reverse;
}

//six
.flex:nth-of-type(1) .cube:nth-of-type(3) {
  animation: rotation 3s ease-in-out 1s infinite alternate;
}
.flex:nth-of-type(2) .cube:nth-of-type(4) {
  animation: rotation 3s ease-in-out 0.8s infinite alternate-reverse;
}

//seven
.flex:nth-of-type(1) .cube:nth-of-type(4) {
  animation: rotation 3s ease-in-out 0.8s infinite alternate-reverse;
}

@keyframes rotation {
  100% {
    transform: rotateX(270deg) rotateY(90deg);
  }
}

// @keyframes border_anim {

//   0%{
//     border-color: $colorEnphasis
//   }
//   50% {
//     border-color: $mainFontColor
//   }
//   100%{
//     border-color: $colorSecondary
//   }
// }


/* cube */
