@import "../../styles/helpers/variables"
@import "../../styles/helpers/mixins"

.center-flex
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    min-height: 70vw
    margin: 7% 0
    overflow: hidden
    @media screen and(max-width: 450px)
        min-height: 100vh

.section-text-column
    position: relative
    @include centerFlex($direction: row,$justifyContent: center)
    row-gap: 20px

.v-img-wrap
    top: 30%
    position: absolute
    z-index: 0
    width: 30%
    transform: translateX(-50%)
    left: 50%
    overflow: hidden
    @media screen and (max-width: 1024px)
        width: 50%

    img
        position: relative 
        

    @media screen and (max-width: 1024px)
        width: 50%

    @media screen and (max-width: 450px)
        width: 90%

.cards-section
    padding: 3%
    position: relative
    z-index: 1
    h1
        margin-bottom: 3rem
        font-size: clamp(1vw,65px,10vw)

.column-layout
    position: relative
    @include centerFlex($direction: row ,$justifyContent: space-between )
    width: 100%
    row-gap: 5px
    column-gap: 5px

    .cards-info
        flex: 1
        padding: 1vw
        height: 325px
        border-radius: 5px
        border: 1px solid $mainFontColor
        transition: all 0.5s ease-in-out
        h3
            font-size: 32px
            margin-bottom: 5%
        p
            font-weight: 200
            font-size: clamp(0.9vw,15px,1rem)

        @media screen and (max-width: 1024px)
            border: none
           
        
        

       



.wrap-text

    width: 40%
    color: $BackgroundColor

    @media screen and (max-width: 450px)
        width: 90%

    h3
        font-size: 35px
        margin-bottom: 2vh

    p
        font-weight: 200
        font-size: 18px
        max-width: 500px

.circle
    width: 62vw
    height: 62vw
    border-radius: 50%
    border: 1.5px solid $BackgroundColor
    // background-color: $BackgroundColor
    position: absolute
    z-index: -1
    left: 22%
    @media screen and (max-width:450px)
        width: 160vw
        height: 160vw
        top: -50px
.dark-rounded
    height: 100vh
    background-color: #231C07
    border-top-left-radius: 110px
    border-top-right-radius: 110px
    overflow: hidden
    padding: 5%
    h1
        color: $mainFontColor
        font-size: 55px

.cv_button
    display: inline-block
    background-color: $BackgroundColor
    color: $mainFontColor
    font-size: 2vw
    font-weight: 500
    border: 1px solid $mainFontColor
    padding: 15px 35px
    border-radius: 4px
    position: relative
    left: 0%
    transform: translateY(-3px)
    transition: all 0.1s ease-in-out
    svg
        *
            fill: $mainFontColor

    @media screen and (max-width: 600px)
        font-size: 5vw
        
    
        
    &:hover
        background-color: $mainFontColor
        color: $BackgroundColor
        transform: translateY(0px)
        box-shadow: 0px 0px 0px 0px rgba(255,255,255,0.5)
        svg
            *
                fill: $BackgroundColor

    a
        display: flex
        justify-content: center
        align-items: center
        column-gap: 10px
        text-decoration: none
        color: inherit
        svg
            width: 3vw
            @media screen and (max-width: 600px)
                width: 7vw
    
