@import "../../styles/helpers/variables"
@import "../../styles/helpers/mixins"



.content-wrapper
    background-color: $BackgroundColor
    height: 100%
    @include centerFlex($direction: row ,$justifyContent: center )
    row-gap: 20px
    column-gap: 10%
.form-style
    display: flex
    flex-direction: column
    flex: 1
    width: 100%
    label
        color: $mainFontColor
        display: block
        font-size: clamp(0.8vw,20px,4vw)
        margin-top: 2%
    input
        background: none
        border: none
        padding: 8px 10px
        border-radius: 0px
        border-bottom: 1px solid $mainFontColor
        width: 100%
        font-size: clamp(0.8vw,20px,4vw)
        color: $mainFontColor
        &:focus
            outline: none
    textarea
        width: 100%
        color: $mainFontColor
        background-color: $BackgroundColor
        border: 1px solid $mainFontColor
        font-size: clamp(1vw, 20px, 4vw)
        align-self: center
        resize: none
        margin-top: 20px
        margin-bottom: 50px
        padding: 1%
    .submit-btn
        margin-top: 20px
        color: $mainFontColor
        border: 1px solid $mainFontColor
        font-size: clamp(1vw,32px,2vw)
        display: block
        padding: 1rem 1rem
        border-radius: 5px
        width: 50%
        align-self: center
        background-color: $BackgroundColor
        cursor: pointer
        transition: all 0.1s ease-in-out

        &:hover

            background-color: $mainFontColor
            color: $BackgroundColor

.submission-message
    width: 100%
    height: 100vh
    display: flex
    align-items: center
    justify-content: center
    background-color: $BackgroundColor
    color: $mainFontColor

.contact-info-page
    white-space: pre-line
    align-self: flex-start

    @media screen and (max-width: 450px)
        text-align: center
        align-self: center
