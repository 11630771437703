@import "../../../styles/helpers/variables"
@import "../../../styles/helpers/mixins"

.navbar
    @include navBarElement($BackgroundColor,$mainFontColor)
    transition: transform 0.2s ease-in-out
    .nav_links
        a
            color: inherit
            text-decoration: none
            padding: 0 20px 0 10px
            transition: all 0.2s ease-in-out
            &:hover
              background-color: $mainFontColor
              color: $BackgroundColor




/* CustomDropdown.css */
.custom-dropdown 
  position: relative
  display: inline-block


.selected-option 
  cursor: pointer
  padding: 12px
  border-radius: 4px


.options 
  position: absolute
  overflow: hidden
  top: 100%
  left: 0
  width: 100%
  background-color: $BackgroundColor
  z-index: 1
  height: 0px
  transition: height 0.2s ease-in-out
  padding-top: 4px
  border-radius: 0 0 4px 4px
  border-top: none


.options.open 
  height: 70px


.options div 
  cursor: pointer
  padding: 5px 12px


.options div:hover 
  background-color: #f0f0f0
  transition: background-color 0.2s ease-in-out
