@import "../helpers/variables"
@import "../helpers/mixins"

*
    margin: 0
    box-sizing: border-box
    text-decoration: none
    &::-webkit-scrollbar
        width: 7px

    &::-webkit-scrollbar-track 
        background: $mainFontColor

    &::-webkit-scrollbar-thumb
        background-color: $BackgroundColor
    
    // maybe? cause glitch in mobile
    // animation: borderAnimation 8s ease-in-out infinite alternate

    // @keyframes borderAnimation
    //     0%
    //         border-color: $colorEnphasis
    //     50%
    //         border-color: $mainFontColor
    //     100%
    //         border-color: $colorSecondary
        
    

// html
//     overflow-x: hidden
//     overflow-y: hidden

body
    border: 1px solid white

    font-family: $fontFamily
    background: $BackgroundColor
    color: $mainFontColor
    // scroll-bar
   
        

img
    max-width: 100%
    height: auto


h1
    font-weight: 600
    font-size: clamp(5vw,90px,11vw)
    text-align: center
    margin: 1.3vw 0 
    span
        color: $mainFontColor
        transition: all 0.3s ease-in-out
        transform: translateY(100px)
        &.emphasis
            color: $colorEnphasis

h2
    font-weight: 600
    margin: 1.3vw 0

h3
    font-weight: 600
    margin: 1.1vw 0

p 
    font-size: clamp(12px,19px,4.2vw)
    font-weight: 300
    margin-bottom: 5px
    margin-top: 5px
    span
        position: relative
        display: inline-block
        margin-left: 15px
        color: $mainFontColor
        transition: all 0.5s ease-in-out
        &::after
            content: ""
            display: block
            z-index: 10
            position: absolute
            width: 0px
            background-color: $colorEnphasis
            transition: all 0.5s ease-in-out
    
        &.emphasis
            color: $BackgroundColor
            &::after
                content: ""
                bottom: 0px
                width: 100%
                height: 100%
                background-color: $colorEnphasis
                mix-blend-mode: multiply
                margin-top: 5px
                transition: all 0.5s ease-in-out

a
    text-decoration: none
    color: $mainFontColor

li 
    font-size: clamp(12px,18px,4.5vw)
    margin-bottom: 5px
    margin-top: 5px


button
    display: block
    width: 100%
    background: none
    padding: 8px 16px
    color: $BackgroundColor
    border: none
    outline: inherit
    cursor: pointer
    transition: all 0.1s ease-in-out
    &:hover
        background-color: $colorEnphasis
        color: $mainFontColor
    
    &.inline
        display: inline-block
        padding: 14px 18px
        max-width: 110px
        border: 1px solid $mainFontColor
        background-color: none
        color: $mainFontColor
        transition: all 0.2s ease-in-out
        &:hover
            background-color: $mainFontColor
            color: $BackgroundColor
            border-radius: 10px


.dropbtn 
    padding: 8px 16px 
    border: 1px solid $mainFontColor     
    cursor: pointer 
    color: $mainFontColor
    background-color: $BackgroundColor
    transition: all 0.2s ease-in-out

    &:hover
        background-color: $mainFontColor
        color: $BackgroundColor
        border-radius: 10px




/* The container <div> - needed to position the dropdown content */
.dropdown  
    position: relative 
    display: inline-block
    /* Show the dropdown menu on hover */
    &:hover 
        .dropdown-content  
            // display: block 
            background-color: $mainFontColor
            border-radius: 10px
            width: 100%
            opacity: 1
            transform: translateY(10px)
 

/* Dropdown Content (Hidden by Default) */
.dropdown-content  
    // display: none 
    overflow: hidden
    position: absolute 
    z-index: 1
    cursor: pointer
    width: 100%
    opacity: 0
    transition: all 0.2s ease-in-out
    transform: translateY(-10px)
    a 
        padding: 12px 16px 
        text-decoration: none 
        display: block
    


 

  
 

    

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover 
    .dropbtn  
        // background-color: #3e8e41 
 


	

//     /* width */
// ::-webkit-scrollbar 
//   width: 10px 


// /* Track */
// ::-webkit-scrollbar-track 
//   background: #f1f1f1


// /* Handle */
// ::-webkit-scrollbar-thumb 
//   background: #888 


// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover  
//   background: #555 
//  