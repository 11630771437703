@import "../helpers/variables"
@import "../helpers/mixins"

// main colors light theme
// $mainFontColor: #EDF6F0
// $BackgroundColor: #231C07
// $colorSecondary: #7F96FF
// $colorTerciary: #DEFF7F
// $colorEnphasis: #FF7F7F
// font
// $fontFamily: 'Josefin Sans', sans-serif

// hero container styles

.hero-wrapper
    display: flex
    position: relative
    justify-content: center
    align-items: center
    width: 100%
    height: 102vh
    background-color: $BackgroundColor
    overflow: hidden

    @media screen and(max-width: 450px)
        height: 62vh
    .hero-paralax-image
        position: absolute
        top: 50%
        right: 0%
        left: 50%
        bottom: 0%
        z-index: 0
        transform: translate(-50%,-50%)

    .hero-content
        color: $mainFontColor
        position: absolute
        right: 2%
        bottom: 17%
        h1
            // position: absolute
            font-size: clamp(1vw, 190px, 13vw)
            font-weight: 800
            line-height: 85%

        p
            font-size: 16px
            font-weight: 200

        @media screen and(max-width: 450px)
            bottom: 8%
            right: 0
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
            h1
                font-size: 75px
            p
                font-size: 14px
            .flag-img
                bottom: -20%
                max-width: 13%

            .circle-line
                max-width: 6%
                top: -10%
                right: 28%

    .location-text
        color: $mainFontColor
        position: absolute
        left: -2%
        transform: rotate(-90deg)
        @media screen and(max-width: 450px)
            font-size: 11px

    .flag-img
        position: absolute
        right: 2%

    img
        max-width: none

    .circle-line
        position: absolute
        right: 25.5%

//card container

.cards-wrapper
    position: relative
    margin-top: 200px
    // background: green
    overflow-x: auto 
    &::-webkit-scrollbar-track 
        background: none
    @media screen and (max-width: 450px)
        height: 95vh
    


.cards-content
    height: 100vh
    display: flex
    flex-direction: row
    padding: 10vh 0
    overflow: visible

.card-holder
    // border: 1px solid $colorEnphasis
    width: 180px
    height: 100%

.card-3d
    min-width: 550px
    width: 550px
    height: 650px
    overflow: hidden
    border-radius: 4px
    transform-style: preserve-3d
    perspective: 10px
    border: 1px solid $colorEnphasis
    transform: rotatex(6deg) rotateY(50deg) translateX(-280px) translateY(80px)
    transition: all 0.3s ease-in-out
    font-weight: 200
    backdrop-filter: blur(10.2px)
    -webkit-backdrop-filter: blur(5px)
    background: rgba(0, 0, 0, 0.8)
    border-radius: 30px

   
    &.slide
        transform: rotatex(6deg) rotateY(50deg) translateY(80px)
        opacity: 1
    

    &.open
        overflow: auto
        border-radius: 3px

        transform: translateX(-100px) translateY(-50px)
        width: 500px
        height: 80vh
        min-width: 500px
        @media screen and (max-width: 450px)
            transform: translateX(-90px) translateY(-30px)
            height: 100vh
            width: 100vw
            min-width: 400px


           

    @media screen and (max-width: 450px)
        width: 380px
        height: 70vh
        backdrop-filter: none
        -webkit-backdrop-filter: none
        background: none
        background-color: $BackgroundColor


   
           

    .image-container
        max-height: 650px



    .card-content
        padding: 3%
        h1
            text-align: right
            font-size: clamp(2vw,46px,11vw)
            margin: 2% 
            @media screen and(max-width: 450px)

        div 
            display: flex
            flex-direction: row
            justify-content: flex-end
            align-items: center
            margin-top: 10px
            column-gap: 10px
          
        
        a
            display: inline-block
            color: $mainFontColor
            text-decoration: none
            font-weight: 300
            border: 1px solid $mainFontColor
            padding: 12px 18px
            transition: all 0.2s ease-in-out

            &:hover
                background-color: $mainFontColor
                color: $BackgroundColor
                border-radius: 10px


            &:active
                background-color: $colorTerciary
            @media screen and (max-width: 450px)
                font-size: 16px

    p
        font-size: clamp(0.4vw,18px,19px)
        font-weight: 300
        text-align: right
        margin-bottom: 20px

      

// section wrapper

.section-wrapper
    background-color: $mainFontColor

    h1
        font-size: clamp(3vw,80px,11vw)
        margin: 3% 0

// container wrapper

.container-wrapper

    margin: 0 auto 0 auto
    max-width: 100%
    padding: 4vw
    padding-bottom: 0

    @media screen and (max-width: 450px)
        padding: 1%
    .flex-column
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        column-gap: 2vw
        row-gap: 2vw

        > a
            text-decoration: none
            color: inherit
            background-color: aliceblue
            padding: 2.5% 3.5%
            font-size: clamp(1vw,22px,5.5vw)
            border-radius: 4px
            margin-top: 10px
            align-self: flex-end
            transition: all 0.2s ease-in-out
            border: 1.5px solid $BackgroundColor
            margin-bottom: 50px
            &:hover
                background-color: $colorEnphasis

            &:active
                background-color: $colorTerciary

// generic hero wwrapper 
.generic-hero-wrapper
    @include centerFlex($direction: row,$justifyContent: center)
    position: relative
    height: 100vh
    color: $BackgroundColor
    width: 100%
    margin: auto
    transition: all 0.5s ease-in-out 0.5s
    color: $mainFontColor
    overflow: hidden
    z-index: 1
    @media screen and (max-width: 450px)
        height: 70vh

.paragraph-big

    font-size: clamp(2vw,70px,8vw)

    line-height: 110%
    transition: color 0.5s ease-in-out

    @media screen and (max-width: 450px)
        font-size: 29px
        text-align: center

.paragraph-small

    font-size: clamp(2vw,30px,5vw)

    font-weight: 100
    @media screen and (max-width: 450px)
        text-align: center
        font-size: 20px
